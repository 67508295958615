var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('NavigationBar',{attrs:{"titel":"Teamplaner","dense":"","isPreview":""}}),_c('section',{staticClass:"mt-2 mx-2"},[_c('v-card',{attrs:{"height":"100%"}},[_c('div',{staticClass:"table-container"},[_c('table',{staticClass:"table-layout"},[_c('thead',[_c('tr',[_c('th',{staticClass:"team-header rounded transparent"}),_vm._l((_vm.events),function(event){return _c('th',{key:event.id,staticClass:"event-header rounded ELEV",class:_vm.$vuetify.theme.dark ? ['border-dark'] : ['border-light']},[_c('v-card',{attrs:{"flat":"","link":""}},[_c('v-card-title',{staticClass:"font-weight-medium text-left subtitle-2 pb-0 px-2 d-block text-truncate"},[_vm._v(" Abenddienst (5h) ")]),_c('v-card-text',{staticClass:"text-left px-2"},[_c('small',[_vm._v(_vm._s(event.start))])])],1)],1)})],2)]),_c('tbody',[[_c('tr',{staticClass:"v-row-group__header role-header"},[_c('td',{staticClass:"role-name rounded ELEV",class:_vm.$vuetify.theme.dark
                      ? ['border-dark', 'grey darken-2']
                      : ['border-light', 'grey lighten-3']},[_c('v-card',{staticClass:"d-block align-center text-truncate transparent",attrs:{"flat":"","link":"","height":"100%"},on:{"click":_vm.toggleUnits}},[_c('v-list-item',{staticClass:"px-2",attrs:{"dense":""}},[_c('v-icon',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.showUnits ? "mdi-chevron-down" : "mdi-chevron-right")+" ")]),_c('span',{staticClass:"font-weight-medium ml-2 text-truncate"},[_vm._v("Einsatzmittel")])],1)],1)],1)]),(_vm.showUnits)?_vm._l((_vm.units),function(unit){return _c('tr',{key:unit.id,staticClass:"member-row"},[_c('td',{staticClass:"member-name rounded ELEV",class:_vm.$vuetify.theme.dark ? ['border-dark'] : ['border-light']},[_c('v-card',{staticClass:"d-block align-center text-truncate",attrs:{"flat":"","link":"","height":"100%"}},[_c('v-list-item',{staticClass:"px-2"},[_c('v-avatar',{staticClass:"mr-2",attrs:{"rounded":""}},[_c('v-img',{attrs:{"contain":"","src":unit.photoUrl}})],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"subtitle-1 font-weight-medium"},[_vm._v(_vm._s(unit.title))]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(unit.crewCombination))])],1)],1)],1)],1),_vm._l((_vm.events),function(event){return _c('td',{key:event.id,staticClass:"response-status rounded ELEV",class:_vm.$vuetify.theme.dark ? ['border-dark'] : ['border-light']},[_c('v-card',{staticClass:"d-flex align-center text-center transparent",attrs:{"flat":"","link":"","height":"100%"}},[_c('v-card-text',[_vm._v(" BESETZBARKEIT ")])],1)],1)})],2)}):_vm._e()],[_c('tr',{staticClass:"v-row-group__header role-header"},[_c('td',{staticClass:"role-name rounded ELEV",class:_vm.$vuetify.theme.dark
                      ? ['border-dark', 'grey darken-2']
                      : ['border-light', 'grey lighten-3']},[_c('v-card',{staticClass:"d-block align-center text-truncate transparent",attrs:{"flat":"","link":"","height":"100%"},on:{"click":_vm.toggleTasks}},[_c('v-list-item',{staticClass:"px-2",attrs:{"dense":""}},[_c('v-icon',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.showTasks ? "mdi-chevron-down" : "mdi-chevron-right")+" ")]),_c('span',{staticClass:"font-weight-medium ml-2 text-truncate"},[_vm._v("Aufgaben")])],1)],1)],1)]),(_vm.showTasks)?_vm._l((_vm.tasks),function(task){return _c('tr',{key:task.id,staticClass:"task-row"},[_c('td',{staticClass:"task-cell rounded ELEV",class:_vm.$vuetify.theme.dark ? ['border-dark'] : ['border-light']},[_c('v-card',{staticClass:"d-block align-center text-truncate",attrs:{"flat":"","link":"","height":"100%"}},[_c('v-list-item',{staticClass:"px-2"},[_c('v-avatar',{staticClass:"mr-2",attrs:{"rounded":"","color":_vm.$vuetify.theme.dark
                              ? 'grey darken-3'
                              : 'grey lighten-3'}},[_c('v-icon',[_vm._v("mdi-"+_vm._s(task.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"subtitle-1 font-weight-medium"},[_vm._v(_vm._s(task.title))])],1)],1)],1)],1),_vm._l((_vm.events),function(event){return _c('td',{key:event.id,staticClass:"response-status rounded ELEV",class:_vm.$vuetify.theme.dark ? ['border-dark'] : ['border-light'],staticStyle:{"position":"relative"}},[_c('v-card',{staticClass:"d-flex align-center text-center transparent",attrs:{"flat":"","link":"","height":"100%"}},[_c('v-card-text',[_vm._l((_vm.getAssignedUsers(
                            event,
                            task.id
                          )),function(user,index){return _c('div',{key:user.uid,staticStyle:{"display":"inline-block"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(index < 2)?_c('v-avatar',_vm._g(_vm._b({class:index < 1
                                    ? 'elevation-1'
                                    : 'ml-n2 elevation-1',staticStyle:{"border":"thin solid white !important"},attrs:{"size":35,"color":"grey"}},'v-avatar',attrs,false),on),[_vm._v(" "+_vm._s(user.name.charAt(0))+_vm._s(user.name.charAt(1).toUpperCase())+" ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(user.name))])])],1)}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(
                                _vm.getAssignedUsers(event, task.id).length > 2
                              )?_c('v-avatar',_vm._g(_vm._b({staticClass:"ml-n2 elevation-1",staticStyle:{"border":"1px solid white !important"},attrs:{"size":35,"color":"red"}},'v-avatar',attrs,false),on),[_c('span',{staticClass:"text-high-emphasis"},[_vm._v(" +"+_vm._s(_vm.getAssignedUsers(event, task.id).length - 2)+" ")])]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getAssignedUsers(event, task.id).length - 2)+" weitere")])])],2)],1)],1)})],2)}):_vm._e()],_vm._l((_vm.roles),function(role,index){return [_c('tr',{key:index,staticClass:"v-row-group__header role-header"},[_c('td',{staticClass:"role-name rounded ELEV",class:_vm.$vuetify.theme.dark
                      ? ['border-dark', 'grey darken-2']
                      : ['border-light', 'grey lighten-3']},[_c('v-card',{staticClass:"d-block align-center text-truncate transparent",attrs:{"flat":"","link":"","height":"100%"},on:{"click":function($event){return _vm.toggleGroup(role)}}},[_c('v-list-item',{staticClass:"px-2",attrs:{"dense":""}},[_c('v-icon',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.isGroupOpen(role) ? "mdi-chevron-down" : "mdi-chevron-right")+" ")]),_c('span',{staticClass:"font-weight-medium ml-2 text-truncate"},[_vm._v(_vm._s(role))]),_c('small',{staticClass:"ml-2"},[_vm._v("("+_vm._s(_vm.sortedTeamMembers(role).length)+")")])],1)],1)],1)]),(_vm.isGroupOpen(role))?_vm._l((_vm.sortedTeamMembers(role)),function(member){return _c('tr',{key:member.uid,staticClass:"member-row"},[_c('td',{staticClass:"member-name rounded ELEV",class:_vm.$vuetify.theme.dark ? ['border-dark'] : ['border-light']},[_c('v-card',{staticClass:"d-block align-center text-truncate",attrs:{"flat":"","link":"","height":"100%"}},[_c('v-list-item',{staticClass:"px-2"},[_c('v-avatar',{staticClass:"mr-2",attrs:{"rounded":"","color":_vm.$vuetify.theme.dark
                              ? 'grey darken-3'
                              : 'grey lighten-3'}},[_c('v-img',{attrs:{"contain":"","src":member.photoUrl}})],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"subtitle-1 font-weight-medium"},[_vm._v(_vm._s(member.name))]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(member.position))])],1)],1)],1)],1),_vm._l((_vm.events),function(event){return _c('td',{key:event.id,staticClass:"response-status rounded ELEV",class:_vm.$vuetify.theme.dark
                        ? [
                            'border-dark',
                            _vm.getStatusColor(
                              _vm.getResponseStatus(event, member),
                              'dark'
                            ),
                          ]
                        : [
                            'border-light',
                            _vm.getStatusColor(
                              _vm.getResponseStatus(event, member),
                              'light'
                            ),
                          ]},[_c('v-card',{staticClass:"d-flex align-center text-center transparent",attrs:{"flat":"","link":"","height":"100%"}},[_c('v-card-text',[_c('v-icon',{attrs:{"color":_vm.getStatusColor(
                              _vm.getResponseStatus(event, member),
                              _vm.$vuetify.theme.dark ? 'dark' : 'light',
                              'text'
                            )}},[_vm._v(_vm._s(_vm.getStatusIcon(_vm.getResponseStatus(event, member))))])],1)],1)],1)})],2)}):_vm._e()]})],2),_c('tfoot',[_c('tr',[_c('td',{staticClass:"sum-name rounded ELEV",class:_vm.$vuetify.theme.dark
                    ? ['grey darken-2', 'border-dark']
                    : ['grey lighten-3', 'border-light']},[_c('v-card',{staticClass:"d-block align-center text-truncate transparent",attrs:{"flat":"","link":"","height":"100%"}},[_c('v-list-item',{staticClass:"px-2",attrs:{"dense":""}},[_c('v-icon',{staticClass:"ml-2"},[_vm._v("mdi-sigma ")]),_c('span',{staticClass:"font-weight-medium ml-2 text-truncate"},[_vm._v("Summe")])],1)],1)],1),_vm._l((_vm.events),function(event){return _c('td',{key:event.id,staticClass:"response-status rounded ELEV",class:_vm.$vuetify.theme.dark ? ['border-dark'] : ['border-light']},[_c('v-card',{attrs:{"flat":"","link":""}},[_c('v-card-text',{staticClass:"text-left px-2"},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":"","left":""}},[_vm._v("mdi-check-circle")]),_c('small',[_vm._v("0")]),_c('br'),_c('v-icon',{staticClass:"mr-1",attrs:{"small":"","left":""}},[_vm._v("mdi-minus-circle")]),_c('small',[_vm._v("0")]),_c('br'),_c('v-icon',{staticClass:"mr-1",attrs:{"small":"","left":""}},[_vm._v("mdi-close-circle")]),_c('small',[_vm._v("0")]),_c('br'),_c('v-icon',{staticClass:"mr-1",attrs:{"small":"","left":""}},[_vm._v("mdi-airplane")]),_c('small',[_vm._v("0")]),_c('br'),_c('v-icon',{staticClass:"mr-1",attrs:{"small":"","left":""}},[_vm._v("mdi-help-circle")]),_c('small',[_vm._v("0")]),_c('br'),_c('v-icon',{staticClass:"mr-1",attrs:{"small":"","left":""}},[_vm._v("mdi-sigma")]),_c('small',[_vm._v("0")])],1)],1)],1)})],2)])])])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }