<template>
  <div>
    <NavigationBar titel="Teamplaner" dense isPreview></NavigationBar>
    <section class="mt-2 mx-2">
      <v-card height="100%">
        <div class="table-container">
          <table class="table-layout">
            <!-- :style="{
              background:
                $vuetify.theme.themes[$vuetify.theme.dark ? 'dark' : 'light']
                  .background,
            }" -->
            <thead>
              <tr>
                <th class="team-header rounded transparent"></th>
                <!-- elevation-2 -->
                <!-- :class="$vuetify.theme.dark ? 'grey darken-2' : 'white'" -->
                <th
                  v-for="event in events"
                  :key="event.id"
                  class="event-header rounded ELEV"
                  :class="
                    $vuetify.theme.dark ? ['border-dark'] : ['border-light']
                  "
                >
                  <v-card flat link>
                    <v-card-title
                      class="font-weight-medium text-left subtitle-2 pb-0 px-2 d-block text-truncate"
                    >
                      <!-- {{ event.name }} -->
                      Abenddienst (5h)
                    </v-card-title>

                    <v-card-text class="text-left px-2">
                      <!-- <v-icon small left class="mr-1">mdi-calendar-start</v-icon
                      > -->
                      <small>{{ event.start }}</small>
                      <!-- <br />
                      <v-icon small left class="mr-1">mdi-clock-start</v-icon>
                      <small>00:00</small>
                      <br />
                      <v-icon small left class="mr-1">mdi-calendar-end</v-icon
                      ><small>{{ event.end }}</small>
                      <br />
                      <v-icon small left class="mr-1">mdi-clock-end</v-icon>
                      <small>00:00</small>
                      <br />
                      <v-icon small left class="mr-1">mdi-map-marker</v-icon
                      ><small>{{ "FW 1245" }}</small> -->
                    </v-card-text>
                  </v-card>
                </th>
              </tr>
            </thead>
            <tbody>
              <!-- units -->
              <template>
                <!-- header for each user group -->
                <tr class="v-row-group__header role-header">
                  <td
                    class="role-name rounded ELEV"
                    :class="
                      $vuetify.theme.dark
                        ? ['border-dark', 'grey darken-2']
                        : ['border-light', 'grey lighten-3']
                    "
                  >
                    <v-card
                      flat
                      link
                      height="100%"
                      class="d-block align-center text-truncate transparent"
                      @click="toggleUnits"
                    >
                      <v-list-item dense class="px-2">
                        <v-icon class="ml-2"
                          >{{
                            showUnits ? "mdi-chevron-down" : "mdi-chevron-right"
                          }}
                        </v-icon>
                        <span class="font-weight-medium ml-2 text-truncate"
                          >Einsatzmittel</span
                        >
                      </v-list-item>
                    </v-card>
                  </td>
                </tr>

                <!-- responses for each user -->
                <template v-if="showUnits">
                  <!-- user details -->
                  <tr v-for="unit in units" :key="unit.id" class="member-row">
                    <td
                      class="member-name rounded ELEV"
                      :class="
                        $vuetify.theme.dark ? ['border-dark'] : ['border-light']
                      "
                    >
                      <v-card
                        flat
                        link
                        height="100%"
                        class="d-block align-center text-truncate"
                      >
                        <v-list-item class="px-2">
                          <v-avatar rounded class="mr-2">
                            <v-img contain :src="unit.photoUrl"></v-img>
                          </v-avatar>
                          <v-list-item-content>
                            <v-list-item-title
                              class="subtitle-1 font-weight-medium"
                              >{{ unit.title }}</v-list-item-title
                            >
                            <v-list-item-subtitle>
                              {{ unit.crewCombination }}</v-list-item-subtitle
                            >
                          </v-list-item-content>
                        </v-list-item>
                      </v-card>
                    </td>

                    <!-- unit status for each event -->
                    <td
                      v-for="event in events"
                      :key="event.id"
                      class="response-status rounded ELEV"
                      :class="
                        $vuetify.theme.dark ? ['border-dark'] : ['border-light']
                      "
                    >
                      <v-card
                        flat
                        link
                        height="100%"
                        class="d-flex align-center text-center transparent"
                      >
                        <v-card-text> BESETZBARKEIT </v-card-text>
                      </v-card>
                    </td>
                  </tr>
                </template>
              </template>

              <!-- tasks -->
              <template>
                <!-- header for each user group -->
                <tr class="v-row-group__header role-header">
                  <td
                    class="role-name rounded ELEV"
                    :class="
                      $vuetify.theme.dark
                        ? ['border-dark', 'grey darken-2']
                        : ['border-light', 'grey lighten-3']
                    "
                  >
                    <v-card
                      flat
                      link
                      height="100%"
                      class="d-block align-center text-truncate transparent"
                      @click="toggleTasks"
                    >
                      <v-list-item dense class="px-2">
                        <v-icon class="ml-2"
                          >{{
                            showTasks ? "mdi-chevron-down" : "mdi-chevron-right"
                          }}
                        </v-icon>
                        <span class="font-weight-medium ml-2 text-truncate"
                          >Aufgaben</span
                        >
                      </v-list-item>
                    </v-card>
                  </td>
                </tr>

                <!-- responses for each user -->
                <template v-if="showTasks">
                  <!-- user details -->
                  <tr v-for="task in tasks" :key="task.id" class="task-row">
                    <td
                      class="task-cell rounded ELEV"
                      :class="
                        $vuetify.theme.dark ? ['border-dark'] : ['border-light']
                      "
                    >
                      <v-card
                        flat
                        link
                        height="100%"
                        class="d-block align-center text-truncate"
                      >
                        <v-list-item class="px-2">
                          <v-avatar
                            rounded
                            :color="
                              $vuetify.theme.dark
                                ? 'grey darken-3'
                                : 'grey lighten-3'
                            "
                            class="mr-2"
                          >
                            <v-icon>mdi-{{ task.icon }}</v-icon>
                          </v-avatar>
                          <v-list-item-content>
                            <v-list-item-title
                              class="subtitle-1 font-weight-medium"
                              >{{ task.title }}</v-list-item-title
                            >
                          </v-list-item-content>
                        </v-list-item>
                      </v-card>
                    </td>

                    <!-- unit status for each event -->
                    <td
                      v-for="event in events"
                      :key="event.id"
                      class="response-status rounded ELEV"
                      :class="
                        $vuetify.theme.dark ? ['border-dark'] : ['border-light']
                      "
                      style="position: relative"
                    >
                      <v-card
                        flat
                        link
                        height="100%"
                        class="d-flex align-center text-center transparent"
                      >
                        <v-card-text>
                          <div
                            v-for="(user, index) in getAssignedUsers(
                              event,
                              task.id
                            )"
                            :key="user.uid"
                            style="display: inline-block"
                          >
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-avatar
                                  v-if="index < 2"
                                  :size="35"
                                  color="grey"
                                  style="border: thin solid white !important"
                                  :class="
                                    index < 1
                                      ? 'elevation-1'
                                      : 'ml-n2 elevation-1'
                                  "
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  {{ user.name.charAt(0)
                                  }}{{ user.name.charAt(1).toUpperCase() }}
                                </v-avatar>
                              </template>
                              <span>{{ user.name }}</span>
                            </v-tooltip>
                          </div>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-avatar
                                v-if="
                                  getAssignedUsers(event, task.id).length > 2
                                "
                                :size="35"
                                color="red"
                                style="border: 1px solid white !important"
                                class="ml-n2 elevation-1"
                                v-bind="attrs"
                                v-on="on"
                              >
                                <span class="text-high-emphasis">
                                  +{{
                                    getAssignedUsers(event, task.id).length - 2
                                  }}
                                </span>
                              </v-avatar>
                            </template>
                            <span
                              >{{
                                getAssignedUsers(event, task.id).length - 2
                              }}
                              weitere</span
                            >
                          </v-tooltip>
                        </v-card-text>
                      </v-card>
                    </td>
                  </tr>
                </template>
              </template>

              <!-- responses -->
              <template v-for="(role, index) in roles">
                <!-- header for each user group -->
                <tr class="v-row-group__header role-header" :key="index">
                  <td
                    class="role-name rounded ELEV"
                    :class="
                      $vuetify.theme.dark
                        ? ['border-dark', 'grey darken-2']
                        : ['border-light', 'grey lighten-3']
                    "
                  >
                    <v-card
                      flat
                      link
                      height="100%"
                      class="d-block align-center text-truncate transparent"
                      @click="toggleGroup(role)"
                    >
                      <v-list-item dense class="px-2">
                        <v-icon class="ml-2"
                          >{{
                            isGroupOpen(role)
                              ? "mdi-chevron-down"
                              : "mdi-chevron-right"
                          }}
                        </v-icon>
                        <span class="font-weight-medium ml-2 text-truncate">{{
                          role
                        }}</span>
                        <small class="ml-2"
                          >({{ sortedTeamMembers(role).length }})</small
                        >
                      </v-list-item>
                    </v-card>
                  </td>
                  <!-- <td
                  :colspan="events.length"
                  class="role-colspan rounded elevation-2"
                  :class="
                    $vuetify.theme.dark ? 'grey darken-2' : 'grey lighten-3'
                  "
                ></td> -->
                </tr>

                <!-- responses for each user -->
                <template v-if="isGroupOpen(role)">
                  <!-- user details -->
                  <tr
                    v-for="member in sortedTeamMembers(role)"
                    :key="member.uid"
                    class="member-row"
                  >
                    <td
                      class="member-name rounded ELEV"
                      :class="
                        $vuetify.theme.dark ? ['border-dark'] : ['border-light']
                      "
                    >
                      <v-card
                        flat
                        link
                        height="100%"
                        class="d-block align-center text-truncate"
                      >
                        <v-list-item class="px-2">
                          <v-avatar
                            rounded
                            :color="
                              $vuetify.theme.dark
                                ? 'grey darken-3'
                                : 'grey lighten-3'
                            "
                            class="mr-2"
                          >
                            <v-img contain :src="member.photoUrl"></v-img>
                          </v-avatar>
                          <v-list-item-content>
                            <v-list-item-title
                              class="subtitle-1 font-weight-medium"
                              >{{ member.name }}</v-list-item-title
                            >
                            <v-list-item-subtitle>
                              {{ member.position }}</v-list-item-subtitle
                            >
                          </v-list-item-content>
                        </v-list-item>
                      </v-card>
                    </td>

                    <!-- response for each event -->
                    <td
                      v-for="event in events"
                      :key="event.id"
                      class="response-status rounded ELEV"
                      :class="
                        $vuetify.theme.dark
                          ? [
                              'border-dark',
                              getStatusColor(
                                getResponseStatus(event, member),
                                'dark'
                              ),
                            ]
                          : [
                              'border-light',
                              getStatusColor(
                                getResponseStatus(event, member),
                                'light'
                              ),
                            ]
                      "
                    >
                      <v-card
                        flat
                        link
                        height="100%"
                        class="d-flex align-center text-center transparent"
                      >
                        <v-card-text>
                          <v-icon
                            :color="
                              getStatusColor(
                                getResponseStatus(event, member),
                                $vuetify.theme.dark ? 'dark' : 'light',
                                'text'
                              )
                            "
                            >{{
                              getStatusIcon(getResponseStatus(event, member))
                            }}</v-icon
                          >
                        </v-card-text>
                      </v-card>
                    </td>
                  </tr>
                </template>
              </template>
            </tbody>
            <tfoot>
              <!-- responses summary -->
              <tr>
                <td
                  class="sum-name rounded ELEV"
                  :class="
                    $vuetify.theme.dark
                      ? ['grey darken-2', 'border-dark']
                      : ['grey lighten-3', 'border-light']
                  "
                >
                  <v-card
                    flat
                    link
                    height="100%"
                    class="d-block align-center text-truncate transparent"
                  >
                    <v-list-item dense class="px-2">
                      <v-icon class="ml-2">mdi-sigma </v-icon>
                      <span class="font-weight-medium ml-2 text-truncate"
                        >Summe</span
                      >
                    </v-list-item>
                  </v-card>
                </td>

                <!-- responses summary for each event -->
                <td
                  v-for="event in events"
                  :key="event.id"
                  class="response-status rounded ELEV"
                  :class="
                    $vuetify.theme.dark ? ['border-dark'] : ['border-light']
                  "
                >
                  <v-card flat link>
                    <v-card-text class="text-left px-2">
                      <v-icon small left class="mr-1">mdi-check-circle</v-icon
                      ><small>0</small>
                      <br />
                      <v-icon small left class="mr-1">mdi-minus-circle</v-icon
                      ><small>0</small>
                      <br />
                      <v-icon small left class="mr-1">mdi-close-circle</v-icon
                      ><small>0</small>
                      <br />
                      <v-icon small left class="mr-1">mdi-airplane</v-icon
                      ><small>0</small>
                      <br />
                      <v-icon small left class="mr-1">mdi-help-circle</v-icon
                      ><small>0</small>
                      <br />
                      <v-icon small left class="mr-1">mdi-sigma</v-icon
                      ><small>0</small>
                    </v-card-text>
                  </v-card>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </v-card>
    </section>
  </div>
</template>

<script>
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";

export default {
  name: "scheduling-teamplanner",
  components: {
    NavigationBar,
  },
  data() {
    return {
      showUnits: true,
      showTasks: true,
      groupOpenStatus: {},
      teamMembers: [
        {
          name: "Mike Miller",
          uid: "1",
          rolePriority: "2",
          role: "Developer",
          position: "WeL",
          photoUrl:
            "https://static.wixstatic.com/media/4e8031_c39120a1650a43ef912f2225d98f6a9b~mv2.png/v1/fill/w_640,h_554,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/4e8031_c39120a1650a43ef912f2225d98f6a9b~mv2.png",
        },
        {
          name: "John Doe",
          uid: "2",
          rolePriority: "2",
          role: "Developer",
          position: "",
          photoUrl:
            "https://play-lh.googleusercontent.com/dJKA8TMIUXYWhMeFjr_aLCOjZnpz00mfxdK2F8CtsDgdPUuCEjY533buQQX7c5IsEfRj",
        },
        {
          name: "Jane Doe",
          uid: "3",
          rolePriority: "1",
          role: "Manager",
          position: "",
          photoUrl:
            "https://play-lh.googleusercontent.com/i8fGO7LrghUKcBCijVf09Vy_FET5-tCh35O6FTFjkHUMixnCRokmaKMZOKNvf4k2P3Y",
        },
        {
          name: "Lucy Doller",
          uid: "4",
          rolePriority: "1",
          role: "Manager",
          position: "",
          photoUrl:
            "https://i2.wp.com/hypebeast.com/image/2020/07/apple-memoji-update-headwear-masks-hairstyles-6.png?w=1260&format=jpeg",
        },
        {
          name: "Jack Smith",
          uid: "5",
          rolePriority: "3",
          role: "Engineer",
          position: "",
          photoUrl:
            "https://i0.wp.com/hypebeast.com/image/2020/07/apple-memoji-update-headwear-masks-hairstyles-3.png?w=1260&format=jpeg",
        },
        {
          name: "Jill Holly",
          uid: "6",
          rolePriority: "2",
          role: "Langer Text kürzt automatisch wie dieser ",
          position: "",
          photoUrl:
            "https://images.squarespace-cdn.com/content/v1/5ec17a4351c4b27b5eebcbae/1590606047781-OQ9I8ST8NJLUQQA1FX0M/Memoji+Jessy+3.png",
        },
        {
          name: "Larry Rye",
          uid: "7",
          rolePriority: "3",
          role: "Engineer",
          position: "WeL-V",
          photoUrl: "https://tylerjefford.com/assets/images/memoji.png",
        },
        {
          name: "Roe Bert",
          uid: "8",
          rolePriority: "2",
          role: "Developer",
          position: "",
          photoUrl:
            "https://static.wixstatic.com/media/4e8031_c39120a1650a43ef912f2225d98f6a9b~mv2.png/v1/fill/w_640,h_554,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/4e8031_c39120a1650a43ef912f2225d98f6a9b~mv2.png",
        },
      ],
      eventsRaw: [
        {
          uid: "100",
          start: "01.01.2020",
          end: "02.01.2020",
          name: "Event 1",
          units: [
            {
              id: "1",
              title: "LHF 9901/3",
              photoUrl:
                "https://forum.rettungssimulator.online/cms/index.php?attachment/4483-gw-w-man-tgm-13-290-4-4-bl-png/&thumbnail=1",
              crewCombination: "LHF",
            },
            {
              uid: "2",
              title: "LHF 9901/3",
              photoUrl:
                "https://forum.rettungssimulator.online/cms/index.php?attachment/4483-gw-w-man-tgm-13-290-4-4-bl-png/&thumbnail=1",
              crewCombination: "LHF-M",
            },
            {
              id: "3",
              title: "LF 9901 KatS",
              photoUrl:
                "https://forum.rettungssimulator.online/cms/index.php?attachment/4483-gw-w-man-tgm-13-290-4-4-bl-png/&thumbnail=1",
              crewCombination: "",
            },
          ],
          tasks: [
            { id: "91", title: "Task 1", icon: "crown-circle" },
            { id: "92", title: "Task 2", icon: "food" },
          ],
          responses: [
            {
              uid: "7",
              status: "yes",
              assignedTasks: [
                { id: "91", title: "Task 1", icon: "crown-circle" },
              ],
            },
            {
              uid: "4",
              status: "no",
              assignedTasks: [
                { id: "91", title: "Task 1", icon: "crown-circle" },
              ],
            },
            {
              uid: "3",
              status: "yes",
              assignedTasks: [
                { id: "91", title: "Task 1", icon: "crown-circle" },
              ],
            },
            {
              uid: "5",
              status: "yes",
              assignedTasks: [{ id: "92", title: "Task 2", icon: "food" }],
            },
          ],
        },
        {
          uid: "190",
          start: "03.01.2020",
          end: "04.01.2020",
          name: "Event 2",
          tasks: [
            { id: "91", title: "Task 1", icon: "crown-circle" },
            { id: "92", title: "Task 2", icon: "food" },
          ],
          units: [
            {
              id: "1",
              title: "LHF 9901/3",
              photoUrl:
                "https://forum.rettungssimulator.online/cms/index.php?attachment/4483-gw-w-man-tgm-13-290-4-4-bl-png/&thumbnail=1",
              crewCombination: "LHF",
            },
            {
              uid: "2",
              title: "LHF 9901/",
              photoUrl:
                "https://forum.rettungssimulator.online/cms/index.php?attachment/4483-gw-w-man-tgm-13-290-4-4-bl-png/&thumbnail=1",
              crewCombination: "LHF-M",
            },
          ],
          responses: [
            { uid: "8", status: "maybe", assignedTask: [] },
            {
              uid: "3",
              status: "yes",
              assignedTasks: [{ id: "92", title: "Task 2", icon: "food" }],
            },
            {
              uid: "4",
              status: "yes",
              assignedTasks: [{ id: "92", title: "Task 2", icon: "food" }],
            },
          ],
        },
        {
          uid: "300",
          start: "05.01.2020",
          end: "06.01.2020",
          name: "Event 3",
          units: [],
          tasks: [
            { id: "91", title: "Task 1", icon: "crown-circle" },
            { id: "92", title: "Task 2", icon: "food" },
          ],
          responses: [
            { uid: "2", status: "yes", assignedTasks: [] },
            { uid: "5", status: "no", assignedTasks: [] },
            { uid: "6", status: "no", assignedTasks: [] },
          ],
        },
        {
          uid: "400",
          start: "07.01.2020",
          end: "10.01.2020",
          name: "Event 4",
          tasks: [
            { id: "91", title: "Task 1", icon: "crown-circle" },
            { id: "92", title: "Task 2", icon: "food" },
          ],
          units: [
            {
              id: "1",
              title: "LHF 9901/3",
              photoUrl:
                "https://forum.rettungssimulator.online/cms/index.php?attachment/4483-gw-w-man-tgm-13-290-4-4-bl-png/&thumbnail=1",
              crewCombination: "LHF",
            },
            {
              uid: "2",
              title: "LHF 9901/3",
              photoUrl:
                "https://forum.rettungssimulator.online/cms/index.php?attachment/4483-gw-w-man-tgm-13-290-4-4-bl-png/&thumbnail=1",
              crewCombination: "LHF-M",
            },
          ],
          responses: [
            { uid: "1", status: "yes", assignedTask: [] },
            { uid: "5", status: "away", assignedTask: [] },
          ],
        },
        {
          uid: "500",
          start: "09.01.2020",
          end: "11.01.2020",
          name: "Event 5",
          tasks: [
            { id: "91", title: "Task 1", icon: "crown-circle" },
            { id: "92", title: "Task 2", icon: "food" },
          ],
          units: [
            {
              uid: "2",
              title: "LHF 9901/3",
              photoUrl:
                "https://forum.rettungssimulator.online/cms/index.php?attachment/4483-gw-w-man-tgm-13-290-4-4-bl-png/&thumbnail=1",
              crewCombination: "LHF-M",
            },
          ],
          responses: [
            { uid: "2", status: "no", assignedTask: [] },
            { uid: "5", status: "away", assignedTask: [] },
            { uid: "4", status: "no", assignedTask: [] },
            { uid: "3", status: "yes", assignedTask: [] },
          ],
        },
        {
          id: "600",
          start: "09.01.2020",
          end: "11.01.2020",
          name: "Event 6",
          units: [
            {
              id: "1",
              title: "LHF 9901/3",
              photoUrl:
                "https://forum.rettungssimulator.online/cms/index.php?attachment/4483-gw-w-man-tgm-13-290-4-4-bl-png/&thumbnail=1",
              crewCombination: "LHF",
            },
          ],
          tasks: [{ id: "91", title: "Task 1", icon: "crown-circle" }],
          responses: [
            { uid: "2", status: "no", assignedTask: [] },
            { uid: "5", status: "no", assignedTask: [] },
            { uid: "4", status: "no", assignedTask: [] },
            { uid: "3", status: "yes", assignedTask: [] },
          ],
        },
        {
          uid: "800",
          start: "09.01.2020",
          end: "11.01.2020",
          name: "Event 7",
          units: [],
          tasks: [{ id: "92", title: "Task 2", icon: "food" }],
          responses: [
            { uid: "2", status: "no", assignedTask: [] },
            { uid: "5", status: "no", assignedTask: [] },
            { uid: "4", status: "no", assignedTask: [] },
            { uid: "3", status: "yes", assignedTask: [] },
          ],
        },
        {
          id: "800",
          start: "09.01.2020",
          end: "11.01.2020",
          name: "Event 8",
          units: [
            {
              id: "1",
              title: "LHF 9901/3",
              photoUrl:
                "https://forum.rettungssimulator.online/cms/index.php?attachment/4483-gw-w-man-tgm-13-290-4-4-bl-png/&thumbnail=1",
              crewCombination: "LHF",
            },
          ],
          tasks: [
            { id: "91", title: "Task 1", icon: "crown-circle" },
            { id: "92", title: "Task 2", icon: "food" },
          ],
          responses: [
            { uid: "1", status: "yes", assignedTask: [] },
            { uid: "5", status: "no", assignedTask: [] },
            { uid: "7", status: "no", assignedTask: [] },
            { uid: "4", status: "no", assignedTask: [] },
            { uid: "3", status: "yes", assignedTask: [] },
          ],
        },
      ],
    };
  },
  computed: {
    events() {
      return this.eventsRaw.map((event) => {
        return {
          ...event,
          tasks: event.tasks.map((task) => {
            const assignedToThisTask = this.teamMembers.filter((member) => {
              const isAssigned = event.responses.filter((response) => {
                const isTaskAssigned =
                  response.uid === member.uid &&
                  response.assignedTasks &&
                  response.assignedTasks.some(
                    (assignedTask) => assignedTask.id === task.id
                  );

                return isTaskAssigned;
              });

              return isAssigned.length > 0;
            });

            return {
              ...task,
              assignedToThisTask, // jetzt gibt es alle zugewiesenen Mitglieder aus
            };
          }),
        };
      });
    },
    roles() {
      return [...new Set(this.teamMembers.map((member) => member.role))].sort(
        (a, b) => a.localeCompare(b)
      );
    },
    units() {
      return this.events.reduce((acc, event) => {
        event.units.forEach((unit) => {
          if (!acc.find((u) => u.id === unit.id)) {
            acc.push(unit);
          }
        });
        return acc;
      }, []);
    },
    tasks() {
      return this.events.reduce((acc, event) => {
        event.tasks.forEach((task) => {
          if (!acc.find((t) => t.id === task.id)) {
            acc.push(task);
          }
        });
        return acc;
      }, []);
    },
  },
  created() {
    this.roles.forEach((role) => {
      this.$set(this.groupOpenStatus, role, true);
    });
  },
  methods: {
    toggleUnits() {
      this.showUnits = !this.showUnits;
    },
    toggleTasks() {
      this.showTasks = !this.showTasks;
    },
    toggleGroup(role) {
      this.$set(this.groupOpenStatus, role, !this.groupOpenStatus[role]);
    },
    isGroupOpen(role) {
      return this.groupOpenStatus[role];
    },
    getAssignedUsers(event, taskId) {
      const task = event.tasks.find((task) => task.id === taskId);
      return task ? task.assignedToThisTask : [];
    },
    sortedTeamMembers(role) {
      return this.teamMembers
        .filter((member) => member.role === role)
        .sort((a, b) => a.name.localeCompare(b.name));
    },
    getStatusColor(status, theme = "light", colorScheme = "background") {
      if (colorScheme === "background") {
        switch (status) {
          case "-1":
            return theme === "dark" ? "purple lighten-4" : "purple lighten-4";
          case "0":
          case "Unknown":
            return "blue-grey lighten-2";
          case "1":
          case "yes":
            return theme === "dark" ? "green lighten-4" : "green lighten-4";
          case "2":
          case "no":
            return theme === "dark" ? "red lighten-4" : "red lighten-4";
          case "3":
          case "maybe":
            return theme === "dark" ? "orange lighten-4" : "orange lighten-4";
          case "4":
          case "away":
            return theme === "dark" ? "blue lighten-4" : "blue lighten-4";
          case "":
          default:
            return "blue-grey lighten-2";
        }
      } else {
        switch (status) {
          case "-1":
            return theme === "dark" ? "purple darken-2" : "blue darken-2";
          case "0":
          case "Unknown":
            return "blue-grey darken-2";
          case "1":
          case "yes":
            return theme === "dark" ? "green darken-2" : "green darken-2";
          case "2":
          case "no":
            return theme === "dark" ? "red darken-2" : "red darken-2";
          case "3":
          case "maybe":
            return theme === "dark" ? "orange darken-2" : "orange darken-2";
          case "4":
          case "away":
            return theme === "dark" ? "blue darken-2" : "blue darken-2";
          case "":
          default:
            return "blue-grey darken-2";
        }
      }
    },
    getStatusIcon(status) {
      switch (status) {
        case "-1":
          return "mdi-cancel";
        case "0":
        case "Unknown":
          return "mdi-help-circle";
        case "1":
        case "yes":
          return "mdi-check-circle";
        case "2":
        case "no":
          return "mdi-close-circle";
        case "3":
        case "maybe":
          return "mdi-minus-circle";
        case "4":
        case "away":
          return "mdi-airplane";
        case "":
        default:
          return "mdi-alert-outline";
      }
    },

    getResponseStatus(event, member) {
      const response = event.responses.find(
        (response) => response.uid === member.uid
      );
      return response ? response.status : "Unknown";
    },
  },
};
</script>

<style scoped>
table {
  border-spacing: 6px;
  border-collapse: separate;
}
table > thead > tr > th {
  position: sticky;
  top: 0;
}
tbody {
  display: table-row-group;
  vertical-align: middle;
  unicode-bidi: isolate;
  border-spacing: 0;
}

tr {
  display: table-row;
  vertical-align: inherit;
  unicode-bidi: isolate;
}

.crossed::after,
.crossed::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.crossed::after {
  background: linear-gradient(
    to bottom right,
    transparent calc(50% - 1px),
    rgba(0, 0, 0, 0.12),
    transparent calc(50% + 1px)
  );
}

.crossed::before {
  background: linear-gradient(
    to top right,
    transparent calc(50% - 1px),
    rgba(0, 0, 0, 0.12),
    transparent calc(50% + 1px)
  );
}
.border-light {
  border: thin solid rgba(0, 0, 0, 0.12) !important;
}

.border-light.lighten-4 {
  border: thin solid rgba(0, 0, 0, 0.12) !important;
}

.border-dark {
  border: thin solid hsla(0, 0%, 100%, 0.12) !important;
}

.border-dark.lighten-4 {
  border: thin solid hsla(0, 0%, 100%, 0.12) !important;
}

.table-container {
  overflow-x: auto;
  display: flex;
}

.table-layout {
  table-layout: fixed;
  min-width: max-content;
}

.team-header {
  width: 225px;
  max-width: 190px !important;
  max-height: 100px;
  position: sticky !important;
  left: 0;
  top: 0;
  z-index: 2;
}

.event-header {
  width: 140px;
  max-width: 140px !important;
  position: sticky !important;
  top: 0;
}

.role-header {
  height: 30px;
}

.role-name {
  position: sticky;
  left: 0;
  top: 0;
  border-right: none;
  max-width: 190px !important;
}

.sum-name {
  position: sticky;
  left: 0;
  top: 0;
  border-right: none;
  max-width: 190px !important;
  z-index: 2;
}

.role-colspan {
  border-left: none;
}

.task-row {
  min-height: 55px !important;
  height: 55px !important;
  max-height: 55px !important;
}

.task-cell {
  position: sticky;
  left: 0;
  max-width: 190px !important;
  z-index: 2;
}

.member-row {
  min-height: 55px !important;
  height: 55px !important;
  max-height: 55px !important;
}

.member-name {
  position: sticky;
  left: 0;
  max-width: 190px !important;
  z-index: 2;
}

.response-status {
}
</style>
